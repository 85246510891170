<template>
    <v-progress-circular size="40" indeterminate color="#48ACEF" v-if="flag"></v-progress-circular>
    <div class="page" v-else>
        <div class="pageHeading" >
            <h3>Reference No: 
                <span style="color: #1467BF;" >{{referenceNumber}}</span>
            </h3>
        </div>
        <div class="pageBody">
            <BookDetailsComponent
                :heading="displayHeading"
                :subHeading="displaySubHeading"
                :displayImage="displayImage"
                :bookingId="bookingId"
                @refBookingId="handleBookingId"
            ></BookDetailsComponent>
        </div>
    </div>
</template>
<script>
import BookDetailsComponent from '../../components/appointmentRoomComponents/bookingDetailsComponent.vue';
export default {
    name: 'appointmentLinkSharingRoom',
    components: {
        BookDetailsComponent,
    },
    data(){
        return {
            displayOTPComponent: false,
            thankYouImage :'',  
            displayHeading: 'Your Consultation is Scheduled',
            displaySubHeading: 'You can join the meeting before 10 mins.',
            displayImage: 'https://s3iconimages.mymedicine.com.mm/timeGIF.gif' ,
            bookingId: '',
            flag: true,
            referenceNumber: ''
        }
    },
    mounted() {
        this.bookingId = this.extractLastSegment(window.location.href);
        if (this.bookingId) {
            this.flag = false;
        }
    },
    methods: {
        extractLastSegment(url) {
            let segments = url.split('/');
            return segments[segments.length - 1];
        },
        handleBookingId(bookingID) {
            this.referenceNumber = bookingID;
            this.flag = false;

        }
    }
}
</script>
<style scoped>
.page {
    min-height: auto;
    /* width: 750px; */
    /* min-width: 500px; */
    border-radius: 16px;
    padding: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;
    
    @media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 900px) {
		width: 600px;
	}

	@media (min-width: 901px) {
		width: 750px;
	}
}
.pageHeading {
    display: flex;
    justify-content: space-between;
}
.pageBody {
    margin-top: 70px;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}
.BodyFirstHalf {
    height: 100%;
    width: 60%;
    margin-top: 120px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>