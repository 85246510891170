<template>
    <div>
        <v-progress-circular size="40" indeterminate color="#48ACEF" v-if="dataLoader"></v-progress-circular>
        <div v-else>
            <div class="div1" >
                <img width="75px" height="75px" :src="displayImage" alt="">
                <div class="mt-3" style="margin-left: auto; margin-right: auto; " >
                    <h2>{{ heading }}</h2>
                    <p class="mt-1" style="color: #828282; font-size: large;" >{{ subHeading }}</p>
                </div>
            </div>
            <div class="wholeOTPComponent" >
                <p>Enter Your 
                    <span style="color: black; font-weight: 500;" >4 digit PIN</span>
                </p>
                <div class="enterOTPDiv" >
                    <div style="display: flex; gap: 10px;" >
                        <v-text-field
                            v-for="(digit, index) in otp"
                            :key="index"
                            v-model="otp[index]"
                            class="otp-input"
                            variant="outlined"
                            density="compact"
                            maxlength="1"
                            type="text"
                            ref="otpInputs"
                            @input="moveToNext(index)"
                            @keydown.backspace="moveToPrev(index)"
                            :class="{ 'red-bg': !otp[index], 'blue-bg': otp[index] }"
                        ></v-text-field>
                    </div>
                    <div v-if="invalidOTP" style="font-weight: 600;" >
                        <p style="color: #ED1C24; padding-bottom: 10px;" >
                            {{ errormsg }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-space-around" >
                <v-btn elevation="0" class="submitButton" style="background-color: #1467BF;"
                    :disabled="disableJoinCall || masterDisable"
                    @click="joinNow()"
                    >
                        Join Now
                        <img class="ml-2" src="https://s3iconimages.mymedicine.com.mm/videoCallIcon.svg" alt="">
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
 import axios from "axios";
export default {
    name: 'bookingDetailsComponent',
    props: {
        bookingId: {
            type: String,
            required: true
        },
        heading: {
            type: String,
            required: true
        },
        subHeading: {
            type: String,
            required: false
        },
        displayImage: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            appointmentDate: '',
            appointmentTime: '',
            PatientName: '',
            DoctorName: '',
            bookingID: '',
            dataLoader: true,
            otp: ['', '', '', ''],
            invalidOTP: false,
            disableJoinCall: true,
            masterDisable: false,
            appointmentID: '',
            finalOTP: '',
            errormsg: '',
            otpVerified: '',
            bookingEpochTime: '',
        }
    },
    mounted() {
        this.dataLoader = true;
        this.fetchParticularAppointmentDetails();
    },
    watch: {
        otp: {
            handler: 'validateForm',
            deep: true
        }
    },
    methods: {
        async fetchParticularAppointmentDetails(){
            try{
                let response = await axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyParticularAppointment", {bookingId: String(this.bookingId)});
                if(response.status === 200){
                    this.appointmentID = response.data.data._id,
                    this.appointmentDate = response.data.data.date_of_appointment,
                    this.appointmentTime = response.data.data.time_slot,
                    this.PatientName = response.data.data.booked_for_name,
                    this.DoctorName = response.data.data.doctor_name,
                    this.bookingID = response.data.data.appointment_reference_number,
                    this.bookingEpochTime = response.data.data.booking_epoch_time
                    this.$emit('refBookingId', this.bookingID);
                }
                this.dataLoader = false;
            } catch(error){
                console.log('Error: ', error.message);
                alert(error.message);
            }

        },
        moveToNext(index) {
            if (this.otp[index] && index < 3) {
            this.$nextTick(() => {
                let nextInput = this.$refs.otpInputs[index + 1] && this.$refs.otpInputs[index + 1].$el.querySelector("input");
                if (nextInput) {
                    nextInput.focus();
                }
            });
        }
        },
        moveToPrev(index) {
            if (!this.otp[index] && index > 0) {
                this.$nextTick(() => {
                    let prevInput = this.$refs.otpInputs[index - 1] && this.$refs.otpInputs[index - 1].$el.querySelector("input");
                    if (prevInput) {
                        prevInput.focus();
                    }
                });
            }
        },
        validateForm() {
            //this.disableJoinCall = (this.otp[3] != "") ? false : true;
            for (let i = 0; i < 4; i++) {
                if (this.otp[i] != "") {
                    this.finalOTP += this.otp[i];
                } else {
                    this.finalOTP = "";
                    break;
                }
            }
            let currentTime = Date.now();
            let timeDifference = (this.bookingEpochTime - currentTime) / 1000 / 60; // time difference in minutes

            if (timeDifference <= 10 && this.finalOTP.length === 4) {
                this.disableJoinCall = (this.otp[3] != "") ? false : true;
            } else {
                this.disableJoinCall = true;
                if (timeDifference > 10) {
                    this.invalidOTP = true;
                    this.errormsg = 'You can join the call only 10 minutes before the appointment time.';
                }
            }
        },
        joinNow(){
            this.masterDisable = true;
            this.verifyOTP();
        },
        async verifyOTP(){
            this.invalidOTP = false;
            try {
                let response = await axios.post(process.env.VUE_APP_BACKEND_URL + "/joinRegularVideoCallViaOTP", {
                    appointmentID: this.appointmentID,
                    roomPassword: this.finalOTP
                });
                if(response.data.otpVerified === false){
                    this.errormsg = 'Inavlid OTP';
                    this.invalidOTP = true;
                    this.otp = ["", "", "", ""];
                    this.otpInputs = [];
                    this.masterDisable = false;
                } else {
                    this.$router.push({
                        name: 'ThirdPersonVideoCallPage',
                        params: {
                            token: response.data.data.twilio_token,
                            appointmentID: this.appointmentID
                        }
                    })
                }
            } catch (error) {
                console.log('Error: ', error.response.data);
                if(error.response.data.message === 'APPOINTMENT CAN BE JOINED ONLY BEFORE 10 MINUTES'){
                    this.errormsg = 'join the call before 10 mins';
                }else if (error.response.data.message === 'ROOM IS FULL'){
                    alert('Room is full');
                }
                this.invalidOTP = true;
                this.otp = ["", "", "", ""];
                this.otpInputs = [];
                this.masterDisable = false;
            }

        },
    }
}
</script>
<style scoped>
.enterOTPDiv {
    margin-right: 32%;
    margin-left: 32%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    justify-content: center;
    align-items: center;
    align-content: center;
}
.otp-input {
    width: 40px !important;
    text-align: center;
    /* background-color: white; */
}
.submitButton {
    display: flex;
    text-transform: none; 
    background-color: #1467BF; 
    color: white; 
    border-radius: 8px; 
    padding: 20px;
}
::v-deep(.red-bg .v-field__input) {
  background-color: white !important;
  color: white !important;
  border-radius: 4px;
}
::v-deep(.blue-bg .v-field__input) {
  background-color: #1467BF !important;
  color: white !important;
  border-radius: 4px;
}
</style>